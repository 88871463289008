import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { MdOutlineEmail } from "react-icons/md";
import "./contact.css";

const Contact = () => {
    const [message, setMessage] = useState(false);
    const formRef = useRef();
    const handleSubmit = (e) => {
        e.preventDefault();
        setMessage(true);
        emailjs
            .sendForm(
                "service_pfs0d27",
                "template_daoixch",
                formRef.current,
                "FORGYp-5RwwVG6L3S"
            )
            .then(
                (result) => {
                    console.log(result.text);
                },
                (error) => {
                    console.log(error.text);
                }
            );

        e.target.reset();
    };
    return (
        <section id="contact">
            <h5>Get In Touch</h5>
            <h2>Connect with Me</h2>
            <div className="container contact__container">
                <div className="contact__options">
                    <article className="contact__option">
                        <MdOutlineEmail className="contact__option-icon" />
                        <h4>Email</h4>
                        <h5>raveekarna@gmail.com</h5>
                        <a href="mailto:merigogichashvili13@gmail.com">
                            Send a message
                        </a>
                    </article>
                </div>
                <form ref={formRef} onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="Your Full Name"
                        name="from_name"
                        required
                    />
                    <input
                        type="text"
                        placeholder="Your Email"
                        name="from_email"
                        required
                    />
                    <textarea
                        placeholder="Your message"
                        rows="7"
                        name="message"
                        required></textarea>
                    <button type="submit" className="btn btn-primary">
                        Send Message
                    </button>
                    {message && <span>Thanks, I'll reply ASAP :)</span>}
                </form>
            </div>
        </section>
    );
};

export default Contact;
