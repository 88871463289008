import React from "react";
import { BsLinkedin } from "react-icons/bs";
import { FaFacebook, FaGithub, FaYoutube } from "react-icons/fa";
import { FaAngellist } from "react-icons/fa";

const HeaderSocials = () => {
    return (
        <div className="header__socials">
            <a
                href="https://www.linkedin.com/in/rabindra-karna-992616140/"
                target="_blank"
                rel="noreferrer">
                <BsLinkedin />
            </a>
            <a href="" target="_blank" rel="noreferrer">
                <FaFacebook />
            </a>
            {/* <a href="https://github.com/Meri-MG" target="_blank" rel="noreferrer" ><FaGithub /></a>
      <a href="https://angel.co/u/meri-gogichashvili" target="_blank" rel="noreferrer" ><FaAngellist /></a> */}
        </div>
    );
};

export default HeaderSocials;
