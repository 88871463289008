import React from "react";
import { FaAward, FaIndustry } from "react-icons/fa";
import { VscFolderLibrary } from "react-icons/vsc";

import dp from "../../assets/rk_dp.png";
import "./intro.css";

const Intro = () => {
    return (
        <section id="about">
            <h5>Get to know</h5>
            <h2>About Me</h2>
            <div className="container about__container">
                <div className="about__me">
                    <div className="about__me-image">
                        <img src={dp} alt="me" className="my_dp" />
                    </div>
                </div>
                <div className="about__content">
                    <div className="about__cards">
                        <article className="about__card">
                            <FaAward className="about__icon" />
                            <h5>Experience</h5>
                            <small>10+ years</small>
                        </article>
                        <article className="about__card">
                            <FaIndustry className="about__icon" />
                            <h5>Companies</h5>
                            <small>5+ Companies worked</small>
                        </article>
                    </div>
                    <p>
                        Result-driven professional targeting assignments in
                        Channel Sales Management, Telecom & FMCG and seeking a
                        challenging career in an organisation that offers a
                        great opportunity and to attain a position in an
                        organisation where my contributions would be vital and
                        my presence would be inevitable.
                    </p>
                    <a href="#contact" className="btn btn-primary">
                        Let's Talk
                    </a>
                </div>
            </div>
        </section>
    );
};

export default Intro;
