import React from "react";
import { BsLinkedin } from "react-icons/bs";
import { FaFacebook, FaGithub } from "react-icons/fa";
import { FaAngellist } from "react-icons/fa";
import "./footer.css";

const Footer = () => {
    let getYear = () => {
        let currentYear = new Date().getFullYear();
        return currentYear;
    };

    return (
        <footer>
            <a href="#home" className="footer__logo">
                Rabindra Karna
            </a>
            <ul className="permalinks">
                <li>
                    <a href="#home">Home</a>
                </li>
                <li>
                    <a href="#about">About</a>
                </li>
                {/* <li>
                    <a href="#experience">Work Experience</a>
                </li> */}
                <li>
                    <a href="#portfolio">Work Experience</a>
                </li>
                {/* <li><a href="#testimonials">Testimonials</a></li> */}
                <li>
                    <a href="#contact">Contact</a>
                </li>
            </ul>
            <div className="footer__socials">
                <a
                    href="https://www.linkedin.com/in/rabindra-karna-992616140/"
                    target="_blank"
                    rel="noreferrer">
                    <BsLinkedin />
                </a>
                {/* <a
                    href="https://github.com/Meri-MG"
                    target="_blank"
                    rel="noreferrer">
                    <FaGithub />
                </a> */}
                <a href="" target="_blank" rel="noreferrer">
                    <FaFacebook />
                </a>
            </div>
            <div className="footer__copyright">
                <small>
                    &copy;{getYear()}. All rights reserved. <br />
                    <span>
                        Developed by{" "}
                        <a
                            href="https://www.alokkarn.tech/"
                            target="_blank"
                            rel="noreferrer">
                            Alok Karn
                        </a>
                    </span>
                </small>
            </div>
        </footer>
    );
};

export default Footer;
