import React from "react";

import "./portfolio.css";

const Portfolio = () => {
    const WorkExperience = [
        {
            company: "Varun Beverages Nepal Pvt. Ltd.",
            location: "Nepal",
            job_title: "Area Sales Manager",
            responsibilities:
                "As a Area Sales Manager at Varun Beverages Nepal Pvt. Ltd., my role involved building a robust network of dealers and distributors in the designated region to meet sales targets. I continuously analyzed regional market dynamics to refine marketing strategies and address brand/packaging gaps. Implementing local sales promotion schemes and setting clear targets for the team were also key aspects of my role. Additionally, I oversaw recruitment, training, and ongoing development of team members, while ensuring effective communication and coordination with stakeholders.",
        },
        {
            company: "OYO",
            location: "Bengaluru, India",
            job_title: "Area Supply Manager",
            responsibilities:
                "In my role as Area Supply Manager at OYO in Bengaluru, India, I was responsible for driving the growth of OYO Life project by generating new property leads and overseeing deal closures. This included managing property updates, addressing queries, and ensuring timely property listings. I maintained strong client relationships, cultivated a network of brokers for lead generation, and oversaw the successful onboarding of properties onto the OYO platform.",
        },
        {
            company: "Tata Teleservices Ltd",
            location: "Bengaluru, India",
            job_title: "Channel Sales Manager",
            responsibilities:
                "As a Channel Sales Manager at Tata Teleservices Ltd in Bengaluru, India, my role revolved around delivering sales targets across all products by executing effective distribution strategies. I focused on acquiring quality customers through the channel network, ensuring stock availability at retail outlets, and driving promotional activities to enhance market credibility. Additionally, I provided regular motivation and support to the sales team, resolved channel-specific issues, and managed distributor relationships.",
        },
        {
            company: "Vodafone South Limited",
            location: "Bengaluru, India",
            job_title: "Channel Sales Manager",
            responsibilities:
                "As a Channel Sales Manager at Vodafone South Limited in Bengaluru, India, my responsibilities included achieving sales targets for all products through effective distribution strategies. I monitored the quality of customer acquisition, ensured product availability at retail, and executed promotional activities to drive sales. Additionally, I focused on expanding retail presence, maintained motivation among stakeholders, and resolved any channel-specific issues to meet revenue targets.",
        },
        {
            company: "Hindustan Unilever Ltd",
            location: "Bengaluru, India",
            job_title: "Territory Sales Officer",
            responsibilities:
                "In my role as Territory Sales Officer at Hindustan Unilever Ltd in North Karnataka-Hubli, India, I was responsible for driving sales targets by managing distributor points and salesmen across different locations. I set monthly targets, facilitated product launches and placements, and ensured salesmen were informed about ongoing schemes. Additionally, I provided support and guidance to salesmen, handled rural sales promoters, and maintained effective communication between the company and distributors.",
        },
        {
            company: "Britannia Industries Ltd",
            location: "Bengaluru, India",
            job_title: "Performance Coach",
            responsibilities:
                "As a Performance Coach at Britannia in India, my responsibilities included planning monthly targets, managing distributor manpower, and ensuring salesmen were equipped to achieve their targets. I oversaw product launches, communicated ongoing schemes to salesmen, and conducted regular performance reviews. Additionally, I supported salesmen in achieving their targets and facilitated coordination among the team.",
        },
    ];

    return (
        <section id="portfolio">
            <h5>My Recent Work</h5>
            <h2>Experience</h2>

            <div className="container portfolio__container">
                {WorkExperience.map((work) => (
                    <article className="portfolio__item" key={work.id}>
                        {/* <div className="portfolio__item-image">
                            <img src={work.img} alt={work.title} />
                        </div> */}
                        <div className="portfolio__item-content">
                            <h3>{work.company}</h3>
                            <h4>{work.job_title}</h4>
                            <p>{work.responsibilities}</p>

                            <h3>{work.location}</h3>
                        </div>
                        {/* <div className="portfolio__item-cta">
                            <a
                                href={pro.github}
                                target="_blank"
                                className="btn"
                                rel="noreferrer">
                                GitHub
                            </a>
                            <a
                                href={pro.link}
                                target="_blank"
                                className="btn btn-primary"
                                rel="noreferrer">
                                Visit Website
                            </a>
                        </div> */}
                    </article>
                ))}
            </div>
        </section>
    );
};

export default Portfolio;
